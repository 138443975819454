import styled from "styled-components";
import { Color, Font, breakpoints, ColorDark } from "@/shared/Constants";
import {
  TwoxTwoWH,
  TwoxTwoTitle,
  TwoxTwoVolanta,
  backgroundPhoto,
} from "../mixins-modules";

export const ContentTwoxTwoFoto = styled.div`
  ${TwoxTwoWH}
  .contentVideo {
    height: auto;
    max-height: 315px;
    aspect-ratio: 16/9;
    min-height: 315px;
    ${breakpoints.phone} {
      min-height: 180px;
    }
  }
  background: ${Color.greyf1f1f1};
  ${breakpoints.darkMode} {
    [data-theme="dark"] & {
      background: ${ColorDark.darkGrey};
    }
  }
  picture {
    &::before {
      ${backgroundPhoto}
    }
  }
  .streaming-box {
    height: 608px;
    width: 100%;
    ${breakpoints.phone} {
      flex-direction: column;
      height: 100%;
      width: 100%;
    }
    .contentIframe {
      width: 642px;
      ${breakpoints.phone} {
        width: 100%;
        height: auto;
      }

      .live-iframe {
        height: 314px;
        ${breakpoints.phone} {
          height: auto;
        }
        > iframe {
          height: 314px;
          ${breakpoints.phone} {
            height: 100%;
          }
        }
      }
    }
  }
  .mt {
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 70%;
    height: 100%;
    ${breakpoints.phone} {
      min-height: 212px;
      padding: 20px 0;
    }
    h2.title,
    .volanta {
      font-size: 36px;
      line-height: 40px;
      ${Font.clarinVarSub2Regular}
      ${breakpoints.phone} {
        font-size: 26px;
        line-height: 30px;
      }
    }
  }
`;
